import "./src/styles/techwind/tailwind.scss";

import React from 'react';

export const onClientEntry = () => {
  const script = document.createElement('script');
  script.src = 'https://www.googletagmanager.com/gtag/js?id=G-HWZ5HGBZQT';
  script.async = true;
  document.head.appendChild(script);

  script.onload = () => {
    const gtagConfig = document.createElement('script');
    gtagConfig.innerHTML = `
      window.dataLayer = window.dataLayer || [];
      function gtag(){dataLayer.push(arguments);}
      gtag('js', new Date());
      gtag('config', 'G-HWZ5HGBZQT');
    `;
    document.head.appendChild(gtagConfig);
  };
};